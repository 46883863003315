@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

#nprogress .bar {
    background: #171717 !important;
}

.ProseMirror p.is-editor-empty:first-child::before {
    @apply text-neutral-400;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

code[class*="language-"],
pre[class*="language-"] {
    color: #f5f5f5;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #262626;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #525252;
}

.token.punctuation {
    color: #d4d4d4;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.keyword,
.token.tag {
    color: #bfdbfe;
}

.token.class-name {
    color: #fef08a;
    text-decoration: underline;
}

.token.boolean,
.token.constant {
    color: #bfdbfe;
}

.token.symbol,
.token.deleted {
    color: #fecaca;
}

.token.number {
    color: #f5d0fe;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #bfdbfe;
}

.token.variable {
    color: #C6C5FE;
}

.token.operator {
    color: #EDEDED;
}

.token.entity {
    color: #bfdbfe;
    cursor: help;
}

.token.url {
    color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
    color: #60a5fa;
}

.token.atrule,
.token.attr-value {
    color: #60a5fa;
}

.token.function {
    color: #60a5fa;
}

.token.regex {
    color: #E9C062;
}

.token.important {
    color: #fd971f;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}
